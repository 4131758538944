"use client";

import { useRef } from "react";
import dynamic from "next/dynamic";

import {
  ImageStyleAvailable,
  ParagraphVideo,
} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { getVariation } from "@/helpers/image";
import PlayBtn from "@/components/atoms/PlayBtn";

interface VideoParagraphProps {
  className?: string;
  data: ParagraphVideo;
  narrow?: boolean;
}

const VideoPlayerNoSSR = dynamic(
  () => import("@/components/atoms/VideoPlayer"),
  { ssr: false },
);

export const VideoParagraph = ({ data, className, narrow }: VideoParagraphProps) => {
  const videoUrl = data.paragraphVideoMedia.mediaOembedVideo as string;
  const boxDisplay = data.entity_type_behaviors.box_display !== "0";
  const containerClass = boxDisplay || narrow ? "container" : "";

  // Get Youtube video id from video url or embed url
  const videoId = videoUrl.includes("embed/")
    ? videoUrl.split("embed/")[1]
    : videoUrl.split("v=")[1];

  const posterUrl = data.paragraphVideoMedia.remoteVideoMediaImage?.variations
    ? getVariation(
        data.paragraphVideoMedia.remoteVideoMediaImage?.variations,
        ImageStyleAvailable.W3800,
      ).url
    : `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;

  const playerRef = useRef();
  const videoPlayer = (
      <>
        <div className="aspect-video">
          <VideoPlayerNoSSR
              playerRef={playerRef}
              videoUrl={videoUrl}
              boxDisplay={boxDisplay}
              playBtn={<PlayBtn />}
              posterUrl={posterUrl}
          />
        </div>
        {data.caption && (
            <div
                className={cn("prose mt-5 text-sm", !boxDisplay && "container")}
                dangerouslySetInnerHTML={{
                  __html: data.caption.processed as string,
                }}
            ></div>
        )}
      </>
  );
  return (
      <div
          className={cn(
              "paragraph paragraph--video",
              containerClass,
              className
          )}
      >
        {narrow && <div className="container">
          <div className="grid grid-cols-12">
            <div className="relative col-span-12 xl:col-span-8 xl:col-start-3 2xl:col-span-6 2xl:col-start-4 overflow-hidden rounded-2xl">
              {videoPlayer}
            </div>
          </div>
        </div>}
        {!narrow && videoPlayer}
      </div>
  );
};
